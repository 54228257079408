import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { ThemeProvider } from 'reflexjs';

import * as gtag from '../lib/gtag';
import theme from '../src/theme';

export default function App({ Component, pageProps }: AppProps) {
    /**
     * @link https://github.com/vercel/next.js/blob/971b17f1e912b8ab69b9ac7cb83617875a2b3d86/examples/with-google-analytics/pages/_app.js
     */
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <ThemeProvider theme={theme}>
            <Component {...pageProps} />
        </ThemeProvider>
    );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
    console.log(metric);
}
